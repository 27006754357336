// deps
import React from "react"
import { graphql, Link } from "gatsby"

// components
import Layout from "../layouts/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default function Home({ location, data }) {
  return (
    <Layout loc={location}>
      <SEO
        title="wie zijn wij?"
        description="welkom in ons portaal voor advies, ondersteuning en onderhouden van het Business Continuity Mangement Proces (BCMP)"
      ></SEO>

      <div className="subHeader" id="homeHeader">
        <div id="pic">
          <Img fluid={data.file.childImageSharp.fluid} alt=""></Img>
        </div>
        <div
          style={{
            display: "grid",
            flexDirection: "column",
            textAlign: "center",
            width: "100%",
          }}
        >
          <h1>
            <span className="line">Business Resilience</span> Consultancy Group
          </h1>
          <h3>
            Eén portaal voor Veerkracht en Continuïteit
            <span id="subdot">.</span>
          </h3>
        </div>
      </div>
      <div className="textContainer homepage">
        <main>
          <h2>
            Wie zijn wij?<span className="dot">.</span>
          </h2>
          <p>
            <span className="yellow">BRCG</span> is een samenwerkingsverband van
            professionals op het gebied van business resilience (veerkracht) en
            bedrijfscontinuïteit. <br /> We zijn dit samenwerkingsverband
            gestart om organisaties één portaal te bieden waar zij terecht
            kunnen voor advies en ondersteuning bij het inrichten en onderhouden
            van hun Business Continuity Management System (BCMS), Risico
            Management en Crisismanagement. Ook faciliteert de BRCG het testen
            en trainen van Business Continuity Plannen (BCP’s) en Crisisteams.
            <br />
            <br />
            <span className="yellow">BRCG</span> zet zich in om de veerkracht
            van bedrijven en organisatie verder te vergroten. Ons uitgangspunt
            is de strategie en doelen van uw organisatie veilig te stellen. Ook
            als het even tegenzit.
            <br />
            <br />
            <span className="yellow">BRCG</span> biedt oplossingen die passen
            bij uw organisatie. Samen met u en uw medewerkers wordt een
            Risicoanalyse (bedreigingen en kwetsbaarheid analyse) en een
            Business Impact Analyse uitgevoerd en stellen we de
            continuïteitsplannen op.
            <br />
            <br />
            <span className="yellow">BRCG</span> zorgt er op deze manier voor
            dat veerkracht en continuïteit onderdeel worden van de dagelijkse
            werkzaamheden en processen. BRCG levert plannen die doelmatig en
            doeltreffend zijn, zonder tijdrovend te zijn.
          </p>
        </main>
        <Link to="/blog" className="blogLink">
          <h3>blog</h3>
          <p>Lees meer over veerkracht en continuiteit in onze blog</p>
          <p id="arrow">></p>
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "algemeen/brcg-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
